.input {
  width: 100%;
  padding: 6px 12px;
  background: $white;
  border: 1px solid $purpleLighten;
  border-radius: 5px;
  font-size: 13px;
  color: $purple;
  min-height: 30px;

  &__error{
    color: $red;
    margin-top: 3px;
    font-size: 8px;
    padding: 0 12px;
  }

  &.error {
    color: $red;
    border-color: $red;
  }

  &__list {
    // margin-top: 15px;
  }

  &__item {
    position: relative;
    
    &:not(:first-child) {
      margin-top: 30px;
    }

    &--card {
      .input {
        font-size: 10px;
        padding-left: 20px;
      }

      &.error {
        .input {
          color: $red;
          border-color: $red;
        }

        // svg {
        //   fill: $red;
        // }

        // path{
        //   fill: $red;
        // }

        .icon-body{
          fill: $red;
        }

        .icon-top{
          stroke: $red;
        }
        
        label {
          color: $red;
        }
      }
    }

    &.error{
      .input__label-top{
        color: $red;
      }
      .input{
        color: $red;
        border-color: $red;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;

    svg{
      flex-shrink: 0;
    }

    &-wrap {
      position: relative;
      flex: 1 0 auto;
      height: 30px;
    }
  }

  &__subtitle {
    margin: 0 15px;
    text-transform: uppercase;
    font-size: 11px;
  }

  &__label {
    position: absolute;
    top: 7px;
    right: 12px;
    font-size: 9px;
    line-height: 10px;
    text-transform: uppercase;
    color: $purple;

    &-top {
      font-size: 8px;
      position: absolute;
      left: 10px;
      padding: 3px;
      background: white;
      top: -6px;
      color: $purpleLighten;
    }
  }

  &__icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;

    svg {
      width: 8px;
      height: auto;
    }
  }

  &__description {
    margin-top: 8px;
    font-size: 8px;
    margin-left: 12px;
    color: $purple;

    &.error {
      color: $red;
    }
  }
}

.info {
  &__item {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    line-height: 1.3;

    p {
      margin: 0;
    }

    &.primary {
      background: $lightPurple;
      color: $purple;
    }

    &.error {
      background: $pink;
      color: $red;
    }

    &.secondary{
      color: #888686;

      a{
        color: currentColor;
      }
    }
  }

  &__highlighted {
    display: inline-block;
    padding: 1px 6px;
    margin: 0 3px;
    color: currentColor;
    background: white;
    border-radius: 8px;
  }
}
