// Typography
$fs: 10px;
$fc: #4d4d4d;

// Colors
$purple: #5280ff;
$lightPurple: #e5edff;
$purpleLighten: #A8C0FF;
$red: #ff2e54;
$pink: #ffe5e5;
$green: #33cc99;
$lightGreen: #98f0d3;
$white: #ffffff;
$grey: #CCCCCC;
