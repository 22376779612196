@import "./scss/variables.scss";

.app {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
  padding-bottom: 30px;

  &__top {
    background-color: $lightPurple;
    padding: 17px 0;

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__main {
    padding: 15px 30px;
    flex: 1 0 auto;

    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__bottom {
    padding-top: 5px;
    text-align: center;
  }

  &__title {
    font-size: 19px;
    font-weight: 500;
    color: $purple;
    padding: 0 40px;
    
    &-inner{
      position: relative;
    }

    &-wr {
      margin: 20px auto 30px;
      text-align: center;
      color: $purple;
    }

    &-icon{
      position: absolute;
      left: -30px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      width: 12px;
      margin-top: -2px;
    }
  }

  &__subtitle{
    margin-top: 2px;
  }

  &__error {
    color: $red;
    font-size: 18px;
  }
}

.button {
  padding: 9px 20px;
  min-width: 150px;
  color: $white;
  background-color: $green;
  border-radius: 20px;
  font-size: 12px;
  line-height: 1.2;
  display: inline-block;

  &:disabled {
    background-color: $lightGreen;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--back {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
    margin-bottom: 25px;
    padding: 0;
    background: transparent;

    svg {
      width: 22px;
    }
  }

  &:hover,
  &:focus-visible{
    opacity: 0.8;
  }
}
