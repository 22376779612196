.result {
  font-size: 17px;
  text-align: center;
  line-height: 1.3;
  color: $purple;
  max-width: 200px;
  width: 100%;
  margin: 0 auto;

  &__inner {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 26px;
      max-height: 26px;
    }
  }

  p {
    margin: 20px 0 0;
  }
}
