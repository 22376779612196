@import '../../scss/variables.scss';

.history{
    margin-top: 20px;


    &__list{
        margin-top: 5px;
        max-height: 120px;
        overflow: auto;
    }

    &__title{
        font-size: 10px;
        font-weight: 500;
        border-bottom: 1px solid $lightPurple;
        padding-bottom: 5px;
    }

    &__row{
        display: flex;
        align-items: center;
        margin: 5px 0;
        font-size: 10px;
        padding: 0;
        transition: color 0.3s ease-in-out;
        color: $fc;
        
        &:hover,
        &:focus-visible{
            color: $purple;
        }
    }

    &__icon{
        margin-left: 10px;
    }

    time{
        color: $grey;
        display: block;
        margin-top: 15px;
    }

    b{
        font-weight: 600;
        margin-left: 2px;
    }
}