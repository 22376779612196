.confirm {
  &__item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 15px;
  }


  &__timer{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background: $lightPurple;
    border-radius: 50%;
    margin-right: 10px;
  }
}
