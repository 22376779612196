.lang_block {
  display: flex;
  align-items: center;
}

.lang_name {
  margin-right: 10px;
  color: $purple;
}

.select {
  &__control {
    width: 38px !important;
    height: 38px !important;
    background-color: $white !important;
    border-radius: 50% !important;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      border: none !important;
      border-color: transparent !important;
      box-shadow: none !important;
      cursor: pointer !important;
    }
  }

  &__indicators {
    display: none !important;
  }

  &__single-value {
    margin: 0 !important;
    color: $purple !important;
  }

  &__option {
    padding-left: 10px !important;
    padding-right: 0 !important;
    color: $purple !important;
    background: white !important;
    cursor: pointer !important;
    
    // &--is-selected {
    //   display: none !important;
    // }
  }
}
