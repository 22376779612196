@import './variables.scss';


html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

// *:not(input) {
//   -webkit-touch-callout: none; /* iOS Safari */
//   -webkit-user-select: none; /* Chrome/Safari/Opera */
//   -khtml-user-select: none; /* Konqueror */
//   -moz-user-select: none; /* Firefox */
//   -ms-user-select: none; /* Internet Explorer/Edge */
//   user-select: none;
// }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $purple;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input,
button {
  outline: none;
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
  outline-width: 0;
}

button:active,
button:hover,
button:focus {
  outline: 0 !important;
  outline-offset: 0;
  box-shadow: none;
}

a {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus,
a:active,
a:disabled {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

address {
  font-style: normal;
}

img {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

input,
button {
  box-shadow: none;
  border: none;
  outline: none;
  font-family: inherit;
  background-color: transparent;
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
  outline-width: 0;
}

button:active,
button:hover,
button:focus,
input:active,
input:hover,
input:focus {
  outline: 0 !important;
  outline-offset: 0;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  /* font-weight: normal; */
}

label {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
  outline-width: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

button {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.container {
  max-width: 600px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  width: 100%;

  &--big {
    padding-left: 20px;
    padding-right: 20px;
  }
}

body {
  font-family: 'Gilroy', sans-serif;
  font-size: $fs;
  line-height: 1.2;
  color: $fc;
  font-weight: 500;
  font-style: normal;
  background-color: $white;
}